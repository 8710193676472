jQuery(window).on("load", function() {
    'use strict';

    jQuery(".c-dashboard__menu").on('click', function(){
        jQuery(".c-menu").addClass("open");
    });

    jQuery(".c-menu__close").on('click', function(){
        jQuery(".c-menu").removeClass("open");
    });
    
});
