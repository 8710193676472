
/** _accordion.js */
jQuery(window).on("load", function() {
    'use strict';
    
    var $target = jQuery('.js-accordionTarget');
        
    // Accordion Click event
    $target.on('click', function() {
        
        // Remove other Open Classes
        jQuery('[class*="accordion"].is-open').removeClass('is-open');
        
        // Set Open Class
        var     $item = $(this).parents('[class*="c-accordion__item"]'),
                $itemContent = $(this).next('.c-accordion__content');
        
        $item.toggleClass('is-open');
        $itemContent.toggleClass('opened');

    });

});