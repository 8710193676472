jQuery(window).on("load", function() {
'use strict';

    // jQuery(".tablesorter tbody").prepend("<tr class='faker'><td>fakerow</td><td>fakerow</td><td>fakerow</td><td>fakerow</td><td>fakerow</td><td>fakerow</td><td></td></tr>");

    jQuery('table.c-table-filter-projects:has(tbody tr)').tablesorter({
        dateFormat: "uk",
        headers: {
            2: { sorter: false },
            7: { sorter: false }
        }
    });

});
