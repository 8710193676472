jQuery(window).on("load", function() {
    'use strict';

    // jQuery('input[type="radio"]').on('click', function() {
    //     // Using value True
    //     if(jQuery(this).hasClass("nocomment") && jQuery(this).attr("value")=="True") {
    //         // Add class to show comment box
    //         jQuery(this).parents(".c-radio__wrap").next(".c-projects__comment").removeClass("open");
    //     }   
        
    //     // Using value False
    //     if(jQuery(this).hasClass("comment") && jQuery(this).attr("value")=="False") {
    //         // Remove class to hide comment box
    //         jQuery(this).parents(".c-radio__wrap").next(".c-projects__comment").addClass("open");
    //     }

    //     // Pass value through to hidden input
    //     jQuery(this).parents(".c-projects__checks").find('.hiddeninput').val(jQuery(this).val());
    // });
 
    jQuery.each(jQuery('input[type="radio"]'), function() {
        if(jQuery(this).attr("value")==jQuery(this).closest(".c-projects__checks").find('.hiddeninput').val() && jQuery(this).hasClass("comment")) {
            jQuery(this).parents(".c-radio__wrap").next(".c-projects__comment").addClass("open");
            jQuery(this).attr('checked', true);
        } else if(jQuery(this).attr("value")==jQuery(this).closest(".c-projects__checks").find('.hiddeninput').val() && jQuery(this).hasClass("nocomment")) {
            jQuery(this).parents(".c-radio__wrap").next(".c-projects__comment").removeClass("open");
            jQuery(this).attr('checked', true);
        }
    });

    jQuery('#approverNo').click(function() {
        if(jQuery(this).attr("value")=="False") {
            jQuery("#additionalFields").addClass('u-hidden');
        }
    });

    jQuery('#approverYes').click(function() {
        if(jQuery(this).attr("value")=="True"){
            jQuery("#additionalFields").removeClass('u-hidden');
        }        
    });

});