jQuery(window).on("load", function() {
    'use strict';

    jQuery(".js-search").focus(function() {
        jQuery(".c-textbox--search").addClass("open");
        jQuery(this).attr('placeholder', 'Seach by Project Name or Number');
    });

    jQuery(document).mouseup(function(e) {
        var container = jQuery(".js-search");

        if (!container.is(e.target) && container.has(e.target).length === 0) 
        {
            jQuery(".c-dashboard__search--inner").addClass("c-dashboard__search-hidden");
            jQuery(".c-textbox--search").removeClass("open");
            jQuery(".js-search").attr('placeholder', 'Search');
        }
    });

    jQuery(".js-search").keyup(function () {
        if(this.value.length < 3) return;
        jQuery(".c-dashboard__search--inner").removeClass("c-dashboard__search-hidden");
    });
    
});
