jQuery(window).on("load", function() {
    'use strict';

    jQuery(function() {
        jQuery('.calendar').pignoseCalendar({
            format: 'DD-MM-YYYY',
            lang: 'en',
            minDate: '2018-06-01'
        });
    });

});