jQuery(window).on("load", function() {
    'use strict';
    
    jQuery(".c-header__notifications").on('click', function(){
        jQuery(".c-header__notifications-box").toggleClass("u-hidden");
        jQuery(".c-header__notifications").toggleClass("open");
        jQuery(".c-header__notification").toggleClass("open");
    });
    
    jQuery(".c-header__notification p").html(function() {
        var numItems = jQuery('.c-header__notifications-box-content p').length;
        return "<p>" + numItems + "</p>";
    });

    // jQuery(".c-header__notifications-box-delete").on('click', function(){
    //     jQuery(this).closest('p').remove();
    // });

    jQuery(".js-deleteIcon").on('click', function(){
        jQuery(this).parent("p").addClass("removed");

        window.setTimeout(function () {
            jQuery(".removed").remove();
        }, 750);
    });
    
});
