jQuery(window).on("load resize", function() {
    'use strict';

    // Change sidebar lists to dropdown on mobile.
    if ((jQuery(window).width() < 1024) && (jQuery('.js-filterProjects').length)) {
        var $list = jQuery('.js-filterProjects'),
            $select = jQuery('<select class="c-select c-select--filter" />');
        $list.children('a').each(function (index) {
            $select.append(jQuery('<option />').attr('value', index).html(jQuery(this).find('p').html()));
        });
        $list.replaceWith($select);
    };
    
});