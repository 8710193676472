$(document).ready(function() {
    
    var length = 20;
    var strip = function(el) {
        el.innerHTML = el.innerHTML.substr(0, length) + '...';
    }

    $ps = $('.c-ellipsis');


    $ps.each(function(i, el) {
        $(el).data('full-text', el.innerHTML);

        strip(el);
    });

    $ps.on('mouseover', function() {
        $(this).text($(this).data('full-text'));
    }).on('mouseout', function() {
        $(this).text(strip(this));
    })

});