jQuery(window).on("load", function () {
    'use strict';

    var container = jQuery('.c-header__help-slideout');

    jQuery('.c-header__help').on('click', function () {
        jQuery('.c-header__help-slideout').addClass('open');

    });

    jQuery('.c-header__help-head-close').on('click', function () {
        jQuery('.c-header__help-slideout').removeClass('open');
        setTimeout(function () {
            jQuery('.c-page-head').removeClass('overflow');
        }, 300);
    });

    jQuery(document).mouseup(function (e) {

        if (!container.is(e.target) && container.has(e.target).length === 0) {
            jQuery('.c-header__help-slideout').removeClass('open');
            jQuery('.c-page-head').removeClass('overflow');
        }

    });

    jQuery('.filter').append('<img src="/img/ui/filter.svg" class="u-p-l2 u-p-l1@s" alt="Filter Icon" />');

    if (jQuery("#createScroll").length) {
        jQuery('html, body').animate({
            scrollTop: jQuery("#createScroll").offset().top
        }, 1000);
    }

});
